@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.navbar-collapse.show {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.bgImage img {
  position: absolute;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  max-height: 100%;
  width: 50%;
  align-self: auto;
  flex: 0 auto;
}

@media only screen and (max-width: 768px) {
  .bgImage img {
    display: none;
  }
}

.show a:not(:last-child) {
  margin-bottom: 0.5rem;
}

.show a {
  width: 100%;
}


