@import "./src/styles/vars";

#notFound404{
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc( 100vh - 65px );
  text-align: center;
}

.bgNotFound{
  position: absolute;
  background: #000000;

  img{
    width: 100vw;
    height: calc( 100vh - 70px);
    object-fit: cover;
    z-index: -1;
    opacity: 0.3;
    object-position: 50% 50%;
  }
}

.notFOundContent{
  position: relative;
  z-index: 999;

  h1, p, a{
    color: #fff;
    font-size: 40px;
    font-weight: bold;
  }

  p, a{
    font-size: 16px;
  }
}