@import "./src/styles/vars";

.Header {
  background-color: rgba(255, 255, 255, 1);
  padding-left: 5%;
  padding-right: 5%;
}

.show {
  .Header {
    display: none;
  }
}

.HeaderMenu {
  transition: all 0.5s ease;
  display: flex;
  margin-right: auto;

  a {
    display: block;
    font-weight: 600;
    padding-right: 8px;
    position: relative;
    color: $main-text-color-dark100;
    font-size: 16px;
    text-decoration: none;
  }
}

.HeaderMenuLogo {
  margin-right: 3rem !important;
  width: 160px;
  img {
    width: 100%;
  }
}

.HeaderUser {
  a {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $main-text-color-dark100;
    text-decoration: none;
    margin-right: 24px;
    font-weight: 600;
  }

  svg {
    margin-right: 8px;
  }
}
