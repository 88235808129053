@import "./src/styles/vars";

.middelware {
    width: 550px!important;
    height: 110px!important;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    background-color: #F7F9FA;
    padding: 7px!important;
    text-align: left;
}
.right {
    float: right;
    margin-right: 24%;
    font-weight: bold;
}
.notes {
    font-weight: bold;
}
.buttonCase {
    padding: 15px 0 25px 0;
}
.taskSection {
    margin: 2rem 0 2rem 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    box-shadow: 0 1px 12px 2px rgba(34, 60, 80, 0.4);
}

.codeSection {
    position: relative;
    padding: 0 5% 50px;
    width: 100%;
    background-color: transparent;
}

.taskSectionHeader {
    margin-bottom: 0;
    background: $main-text-color;
    color: $main-text-color-dark;
    font-size: 24px;
    font-weight: bold;
    padding: 3rem 1rem 0rem 1rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    @media only screen and (max-width: 962px) {
        font-size: 16px;
        padding: 10px;
    }
}

.solutionHints{
    padding: 20px;
    background-color: #fff;
    p{
        margin: 0;
    }
}

.codeBorder {
    border: 1px solid rgba(0,0,0,.125)!important;
    padding: 15px 15px 1px 15px;
}

div.noTaskFound{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 100px);
    text-align: center;
    font-size: 18px;
}