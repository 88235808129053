@import "./src/styles/vars";

.Cards {
  margin-top: 1rem;
  padding-left: 5%;
  padding-right: 5%;
}

.Card {
  padding: 20px;
  color: #fff;
  background: $dark300;
  border-radius: 5px;
  text-align: left;
}

.LinkButton {
  margin-top: auto;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  color: #fff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  a {
    display: block;
    margin: 20px 0 0;
    text-decoration: none;
    border-radius: 4px;
    padding: 8px 16px;
    color: $main-text-color-dark;
    background: $main-text-color;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    transition: all 0.3s ease;

    &:hover {
      background-color: $main-text-color-light;
      color: #fff !important;
      box-shadow: 0 1px 12px 2px rgba(34, 60, 80, 0.1);
    }
  }
}
