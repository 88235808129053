@import "./src/styles/vars";

.profileSection {
  position: absolute;
  width: 100%;
  margin-top: 1rem;
  padding-left: 5%;
  padding-right: 5%;

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  min-height: calc( 100vh - 85px );
  padding-bottom: 20px;
}

.listItem {
  display: flex !important;
  align-items: center;
  margin-bottom: 2rem;
  border-radius: 5px;
  border: 1px solid rgba(0,0,0,.125) !important;
  background: $dark300 !important;
  &:hover {
    background: $dark100 !important;
  }
}

@media only screen and (max-width: 520px) {
  .listItem {
    flex-direction: column;
  }
}

.Cards {
  margin-top: 5rem;
}

.CardBody {
  text-align: left;
  color: #fff;
}

.LinkButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 20px 40px;
  height: 100%;
  p {
    border: 2px solid $green;
    color: $main-text-color;
    cursor: default;
    background: $green;
    border-radius: 5px;
    padding: 0.375rem 0.75rem;
  }
  button {
    width: 120px;
    border: none;
    color: $main-text-color-dark;
    background: $main-text-color;
    font-weight: bold;
    &:hover {
      background-color: $main-text-color-light;
      color: #fff !important;
      box-shadow: 0 1px 12px 2px rgba(34, 60, 80, 0.1);
    }
  }
}

.ResultCard {
  // margin-top: 2rem;
  margin-bottom: 30px;
  background: #fff;
  .descriptions{
    padding: 25px;
    padding-top: 0px;
  }
  .ResultCardHeader {
    margin-bottom: 2rem;
    text-align: center;
    background: $main-text-color;
    color: $main-text-color-dark;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 1px 12px 2px rgba(34, 60, 80, 0.4);
    // font-size: 16px;
    font-weight: bold;
    padding: 10px;
  }
}

.helpItem {
  width: 45%;
  height: 100%;
  float: right;
}

.codeHelpItem {
  overflow-y: scroll;

}

.helpItem div {
  height: 375px !important;
}

.plan {
  display: block;
  margin: 20px 0 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  padding: 12px 24px;
  color: #fff;
  background-color: $green;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  transition: all 0.3s ease;

  &:hover {
    background-color: $green100;
    color: #fff !important;
    box-shadow: 0 1px 12px 2px rgba(34, 60, 80, 0.1);
  }
}
