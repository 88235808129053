@import "./src/styles/vars";

.inValidValue {
    color: rgb(209, 205, 199);
    background-color: rgb(255, 221, 221)!important;
    border-color: rgb(60, 65, 68);
}

.LoginForm {
    margin: 3rem auto 5rem;
    width: 50%;
    border-radius: 5px;
    border: 1px solid $main-text-color;
    box-shadow: 0 1px 12px 2px rgba(34, 60, 80, 0.1);
    padding: 60px;
}

.LoginFormHeader {
    color: $main-text-color-dark !important;
}

@media only screen and (max-width: 992px) {
    .LoginForm {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .LoginForm {
        width: 100%;
    }
}
