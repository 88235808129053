@import "./src/styles/vars";

.middelware {
  width: 550px !important;
  height: 110px !important;
  border: 1px solid #000;
  padding: 7px !important;
  text-align: left;
}

.notes {
  width: 100%;
  margin-bottom: 1rem !important;
  background: $main-text-color;
  color: $main-text-color-blue;
  font-size: 14px;
  padding: 10px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: 0 1px 12px 2px rgba(34, 60, 80, 0.4);
  font-weight: bold;
}

.buttonCase {
  margin: 25px 0 25px 0;
  button {
    padding: 6px 55px;
  }
}

.taskSection {
  padding: 15px 0px 25px 0px;
}

/* .showCode {
    margin-top: 55px;
    position: absolute;
    margin-left: 7px;
    padding: 5px!important;
    opacity: 0.6;
    pointer-events: none;
    cursor: text;
} */
