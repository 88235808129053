@import "./src/styles/vars";

.AdminPanelForm {
    margin-top: 2rem;
    border-radius: 5px;
    border: 1px solid $main-text-color;
    box-shadow: 0 1px 12px 2px rgba(34, 60, 80, 0.1);
    padding: 40px;
}
.codeSection > div{
    height: 150px;
    max-width: 493px;
}
.admin_textarea {
    resize: none!important;
    height: 150px;
}
.rule51 {
    width: 51%!important;
    margin: 10px 0;
}
.rule51Btn, .removeOutputBtn {
    height: 38px;
    margin-top: 10px!important;
}
.removeOutputBtn{
    color: #fff !important;
    // background: #dc3545 !important;
    outline: none !important;
}
.cursorPointer{
    cursor: pointer;
}