@import "./src/styles/vars";

.FeedSection {
  position: relative;
  overflow-x: hidden;

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  min-height: calc( 100vh - 85px );
  padding-bottom: 20px;
  background-position: right;
}

.Cards {
  padding: 0 5% 50px;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 25px;
  @media only screen and (max-width: 1192px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }

}

.Card {
  padding: 20px !important;
  background: $dark300 !important;
  border-radius: 4px;
  box-shadow: 0 1px 12px 2px rgba(34, 60, 80, 0.5);
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  color: #fff;
  font-weight: 600;
  text-align: left;
  height: 100%;
  p {
    flex: 1 0 auto;
    line-height: 1.5;
  }

  opacity: 0.85;

  &:hover{
    opacity: 0.95;
  }
}

.LinkButton {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  a {
    display: block;
    margin: 30px 40px;
    text-decoration: none;
    border-radius: 4px;
    padding: 8px 16px;
    color: $main-text-color-dark;
    background: $main-text-color;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    transition: all 0.3s ease;

    &:hover {
      background-color: $main-text-color-light;
      color: #fff !important;
      box-shadow: 0 1px 12px 2px rgba(34, 60, 80, 0.1);
    }
  }
}
