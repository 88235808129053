// ============================================================================
// Variables
// ============================================================================

$white: #FFFFFF;
$black: #000000;
$dark: #1d1d22;
$dark100: #1D1D22;
$dark200: #28272f;
$dark300: #263238;
$green: #27ae60;
$green100: #34935b;


$main-text-color: #fcfcfc;
$main-text-color-dark: #40414e;
$main-text-color-dark100: #40414e;
$main-text-color-light: #66788a;
$main-text-color-blue: #234361;

$primary-link: #1070CA;
