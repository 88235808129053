.StripeElement {
    width: 70%;
    float: right;
    position: relative;
    top: 50%;
    transform: perspective(1px) translateY(-50%);
    color: #31325F;
}
.container4 {
    position: absolute;
    top: 25%;
    left: 25%;
    width: 600px;
}
.newFooter {
    display: block!important;
}
.toRight {
    text-align: center!important;
}
